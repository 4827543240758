const tvaList = {
  Dubaï: 0.05,
  Allemagne: 0.19,
  Autriche: 0.2,
  Belgique: 0.21,
  Bulgarie: 0.2,
  Chypre: 0.19,
  Croatie: 0.25,
  Danemark: 0.25,
  Espagne: 0.21,
  Estonie: 0.2,
  Finlande: 0.24,
  France: 0.2,
  Grèce: 0.24,
  Hongrie: 0.27,
  Irlande: 0.23,
  Italie: 0.22,
  Lettonie: 0.21,
  Lituanie: 0.21,
  Luxembourg: 0.16,
  Malte: 0.18,
  "Pays-Bas": 0.21,
  Pologne: 0.23,
  Portugal: 0.23,
  "République tchèque": 0.21,
  Roumanie: 0.19,
  Slovaquie: 0.2,
  Slovénie: 0.22,
  Suède: 0.25,
};

export default tvaList;
